.news-container {
    max-width: 800px; /* Adjust this value as needed */
    margin: 0 auto;   /* Centers the container horizontally */
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    height: 100vh;
    text-align: left;
}

.news-container h2 {
    margin-bottom: 20px;
}

.news-container p {
    font-size: 18px;
}