.about-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-container p {
    font-size: 18px;
    line-height: 1.5;
    color: #333;
  }
  
  .about-container h1 {
    color: #444;
    font-size: 24px;
    margin-bottom: 20px;
  }